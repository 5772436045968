import { get } from './get';

export interface GroupedCollection<T> {
  [key: string | number | symbol]: T[];
}

/**
 * Provides a reducer function to transform an array of objects and a key into a
 * dictionary { T[key]: T[] }
 * ```typescript
 * // Who has the same number of sisters as each other?
 * type Person = { name: string, family: { brothers: number, sisters: number }}
 * [
 *   { name: 'Amy', family: { brothers: 0, sisters: 1 } },
 *   { name: 'Denise', family: { brothers: 1, sisters: 2 } },
 *   { name: 'Amy', family: { brothers: 4, sisters: 0 } },
 *   { name: 'Cathy', family: { brothers: 0, sisters: 2 } },
 *   { name: 'Betty', family: { brothers: 2, sisters: 1 } },
 * ].reduce(groupByAsDictionaryReducer('family.sisters'), {} as GroupedCollection<Person>);
 *
 * // Returns:
 * {
 *   '0': [ { name: 'Amy', family: [Object] } ],
 *   '1': [
 *     { name: 'Amy', family: [Object] },
 *     { name: 'Betty', family: [Object] }
 *   ],
 *   '2': [
 *     { name: 'Denise', family: [Object] },
 *     { name: 'Cathy', family: [Object] }
 *   ]
 * }
 * ```
 * @param key - key of T. Can be deeply nested as in 'family.sisters.length', but you'll need to tell
 * typescript it's a keyof T.
 */
export function groupByAsDictionaryReducer<T>(
  key: keyof T
): (accum: GroupedCollection<T>, currentValue: T) => GroupedCollection<T>;
export function groupByAsDictionaryReducer<T>(key: keyof T) {
  return function groupBy(accum: GroupedCollection<T>, currentValue: T): GroupedCollection<T> {
    if (!currentValue) return accum;
    const keyName = get(currentValue as any, key as string);
    if (accum[keyName]) {
      accum[keyName].push(currentValue);
    } else {
      accum[keyName] = [currentValue];
    }
    return accum;
  };
}

/**
 * Provides a reducer function to transform a group of objects into a two
 * dimensional array T[][].
 * ```typescript
 * [
 *   { name: 'Amy', age: 21 },
 *   { name: 'Denise', age: 10 },
 *   { name: 'Amy', age: 5 },
 *   { name: 'Cathy', age: 5 },
 *   { name: 'Betty', age: 21 },
 * ].reduce(groupByAsArrayReducer('age'), []);
 * // Returns:
 * [
 *   [ { name: 'Amy', age: 21 }, { name: 'Betty', age: 21 } ],
 *   [ { name: 'Denise', age: 10 } ],
 *   [ { name: 'Amy', age: 5 }, { name: 'Cathy', age: 5 } ]
 * ]
 * ```
 * @param key
 * @returns
 */
export function groupByAsArrayReducer<T>(
  key: string | number
): (accum: Array<T[]>, currentValue: T) => Array<T[]> {
  return function groupBy(accum: Array<T[]>, currentValue: T): T[][] {
    const groupArray = accum.find(
      (tArray) => get(tArray[0] as any, key) === get(currentValue as any, key)
    );
    if (groupArray) {
      groupArray.push(currentValue);
    } else {
      accum.push([currentValue]);
    }
    return accum;
  };
}

/**
 * Provides a reducer function to transform an array of objects and a key into a
 * dictionary { T[key]: T[] }
 * ```typescript
 * [
 *   { name: 'Amy', age: 21 },
 *   { name: 'Denise', age: 10 },
 *   { name: 'Amy', age: 5 },
 *   { name: 'Cathy', age: 5 },
 *   { name: 'Betty', age: 21 },
 * ].reduce(groupWithReducer(person => person.age), {});
 * // Returns:
 * [
 *   [ { name: 'Amy', age: 21 }, { name: 'Betty', age: 21 } ],
 *   [ { name: 'Denise', age: 10 } ],
 *   [ { name: 'Amy', age: 5 }, { name: 'Cathy', age: 5 } ]
 * ]
 * ```
 * @param key - key of T. Can be deeply nested as in 'family.sisters.length', but you'll need to tell
 * typescript it's a keyof T.
 */
export function groupWithReducer<T>(
  lambda: (value: T) => string | number
): (accum: GroupedCollection<T>, currentValue: T) => GroupedCollection<T>;
export function groupWithReducer<T>(lambda: (value: T) => string | number) {
  return function groupBy(accum: GroupedCollection<T>, currentValue: T): GroupedCollection<T> {
    if (!currentValue) return accum;
    const keyName = lambda(currentValue);
    if (accum[keyName]) {
      accum[keyName].push(currentValue);
    } else {
      accum[keyName] = [currentValue];
    }
    return accum;
  };
}
